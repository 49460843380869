@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@angular/cdk/overlay-prebuilt.css';
@import 'ngx-toastr/toastr';

// custom
@import './themes/container.scss';
@import './themes/typography.scss';
@import './themes/fonts.scss';
@import './themes/scroll.scss';
@import './themes/animations.scss';
@import './themes/theme.scss';

@import 'swiper/css';

* {
  font-family: 'SUIT';
  letter-spacing: -0.04em !important;
  outline: none;
}

body {
  @apply overflow-x-hidden;
}

p.count {
  @apply text-gray-800 font-medium text-sm xl:text-base;

  span {
    @apply text-primary;
  }
}

/* notch screen */
@supports (padding: max(0px)) {
  .post {
    padding-left: max(12px, env(safe-area-inset-left));
    padding-right: max(12px, env(safe-area-inset-right));
  }
}
