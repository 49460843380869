@media screen and (max-width: 768px) {
  ::-webkit-scrollbar {
    display: none;
  }
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 2px;
}
