.theme-text {
  &[data-theme='light'] {
    @apply text-neutral-700;
  }

  &[data-theme='dark'] {
    @apply text-dark-text;
  }
}

.theme-border {
  &[data-theme='light'] {
    @apply border-neutral-200;
  }

  &[data-theme='dark'] {
    @apply border-dark-divider;
  }
}

.theme-divider {
  &[data-theme='light'] {
    @apply border-neutral-200 border-t;
  }

  &[data-theme='dark'] {
    @apply border-dark-divider border-t;
  }
}

.theme-wrapper {
  &[data-theme='dark'] {
    @apply bg-dark-section;
  }

  &[data-theme='light'] {
    @apply bg-white;
  }
}

.theme-sub-text {
  &[data-theme='light'] {
    @apply text-neutral-500;
  }

  &[data-theme='dark'] {
    @apply text-dark-subText;
  }
}

.theme-hover {
  &[data-theme='dark'] {
    @apply hover:bg-dark-hover;
  }

  &[data-theme='light'] {
    @apply hover:bg-neutral-200;
  }
}
