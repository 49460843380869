@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'SUIT';
  font-weight: 100;
  font-style: normal;
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-Thin.woff2')
    format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'SUIT';
  font-weight: 200;
  font-style: normal;
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-ExtraLight.woff2')
    format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'SUIT';
  font-weight: 300;
  font-style: normal;
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-Light.woff2')
    format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'SUIT';
  font-weight: 400;
  font-style: normal;
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-Regular.woff2')
    format('woff2');

  font-display: swap;
}
@font-face {
  font-family: 'SUIT';
  font-weight: 500;
  font-style: normal;
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-Medium.woff2')
    format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'SUIT';
  font-weight: 600;
  font-style: normal;
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-SemiBold.woff2')
    format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'SUIT';
  font-weight: 700;
  font-style: normal;
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-Bold.woff2')
    format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'SUIT';
  font-weight: 800;
  font-style: normal;
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-ExtraBold.woff2')
    format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'SUIT';
  font-weight: 900;
  font-style: normal;
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-Heavy.woff2')
    format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'GmarketSans';
  src: url('/landing/fonts/GmarketSansTTFLight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'GmarketSans';
  src: url('/landing/fonts/GmarketSansTTFMedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'GmarketSans';
  src: url('/landing/fonts/GmarketSansTTFBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

.prompt-semibold {
  font-family: 'Prompt', serif;
  font-weight: 600;
  font-style: normal;
}

.prompt-bold {
  font-family: 'Prompt', serif;
  font-weight: 700;
  font-style: normal;
}

.prompt-extrabold {
  font-family: 'Prompt', serif;
  font-weight: 800;
  font-style: normal;
}

* {
  font-family: 'SUIT', sans-serif;
  font-weight: 500;
}
